import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';
import { StaticImage } from 'gatsby-plugin-image';

const StyledMainContainer = styled.main`
  & > header {
    margin-bottom: 100px;
    text-align: center;

    a {
      &:hover,
      &:focus {
        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>⚡</text></svg>")
            20 0,
          auto;
      }
    }
  }

  footer {
    ${({ theme }) => theme.mixins.flexBetween};
    width: 100%;
    margin-top: 20px;
  }
`;

const TenEastPage = ({ location }) => {

  return (
    <Layout location={location}>
      <Helmet title="10 East" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">10 East</h1>
          <p className="subtitle">
            Highlights of the work I did at 10 East
          </p>
        </header>

        <div>
          Login page; including password reset
          <StaticImage
            className="img"
            src="../../images/10east-login.png"
            // width={528}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="10east-login"
          />
        </div>

        Unfortunately, a lot of the other pages I worked on are private pages, and cannot be shown here.

      </StyledMainContainer>
    </Layout>
  );
};

TenEastPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default TenEastPage;
